<template>
	<div>
		<!-- <el-dialog title="等待支付" :visible.sync="visible" :mask-closable="false" :close-on-click-modal="false" @cancel="handleClose" width="350px" append-to-body destroy-on-close> -->
			<div style="width:100%;margin-bottom:20px;text-align:center">
				<img v-show="apiRes.payDataType == 'codeImgUrl'" :src="apiRes.payData" alt="" />
				<span v-show="apiRes.payDataType == 'payurl'">
					等待用户支付
					<hr />
					如浏览器未正确跳转请点击：
					<a :href="apiRes.payData" target="_blank">支付地址</a>
					<el-button size="small" class="copy-btn" v-clipboard:copy="apiRes.payData" v-clipboard:success="onCopy">复制链接</el-button>
				</span>
				<span v-show="!(apiRes.payDataType == 'codeImgUrl'||apiRes.payDataType == 'payurl')">等待用户支付,请稍后</span>
			</div>
			<!-- <p class="mti-pay-code-describe"> -->
				<!-- <img src="../../assets/img/pay/wx_app.svg" alt=""  /> -->
				<!-- 微信图标 -->
				<!-- <img src="../../assets/img/pay/ali_app.svg" alt=""  /> -->
				<!-- 支付宝图标 -->
				<!-- <span>{{ payText?payText:"支持微信、支付宝扫码" }}</span> -->
			<!-- </p> -->
		<!-- </el-dialog> -->
	</div>
</template>
<script>
import {getPayOrderWebSocketUrl} from "../../api/modules/payTool";
import ReconnectingWebSocket from "reconnectingwebsocket";
import {PAY_WAY_CODE} from "./constants/PayConstants";

export default {
	name: "PayQRCode",
	components: {},
	props:["apiRow"],
	data() {
		return {
			visible: false,
			// 二维码底部描述文字
			payText: "",
			// 微信二维码图片是否展示
			wxApp: true,
			// 支付宝二维码图片是否展示
			aliApp: true,
			// 接口返回数据包
			apiRes: {},
			// 支付订单webSocket对象
			payOrderWebSocket: null,
		};
	},
	created() {
	},
	mounted() {
	},
	methods: {
		onCopy() {
			this.$message.success("复制成功");
		},
		callWebSocket(){
			 this.$nextTick(() => {
				let wayCode=PAY_WAY_CODE.AUTO_QR
				let apiRes=this.apiRes
				this.showModal(wayCode, apiRes)
        })
		},
		showModal(wayCode, apiRes) {
			console.log(654321);
			// 关闭上一个webSocket监听
			if (this.payOrderWebSocket) {
				this.payOrderWebSocket.close();
			}
			// this.apiRes = apiRes;
			
			this.wxApp = false;
			this.aliApp = false;
			this.visible = true;

			// 根据不同的支付方式，展示不同的信息
			this.payText = "";
			if (wayCode === PAY_WAY_CODE.WX_NATIVE || wayCode === PAY_WAY_CODE.WX_JSAPI) {
				// 微信二维码
				this.wxApp = true;
				this.payText = '请使用微信"扫一扫"扫码支付';
			} else if (wayCode === PAY_WAY_CODE.ALI_QR || wayCode === PAY_WAY_CODE.ALI_JSAPI) {
				// 支付宝二维码
				this.aliApp = true;
				this.payText = '请使用支付宝"扫一扫"扫码支付';
			} else if (wayCode === PAY_WAY_CODE.AUTO_QR) {
				// 聚合支付二维码
				this.wxApp = true;
				this.aliApp = true;
				this.payText = "支持微信、支付宝扫码";
			}

			// 此处判断接口中返回的orderState，值为0，1 代表支付中，直接放行无需处理，2 成功 3 失败
			if (apiRes.orderState === 2 || apiRes.orderState === 3) {
				if (apiRes.orderState === 2) {
					this.handleClose();
					const successModal = this.$message.success("支付成功， 2s后自动关闭...");
					setTimeout(() => {
						successModal.destroy();
					}, 2000);
					// 关闭条码框
					this.$emit("open");
				} else if (apiRes.orderState === 3) {
					this.handleClose();
					// 关闭条码框
					this.$emit("open");
					this.$message.error("支付失败：" + apiRes.errCode + " ，" + apiRes.errMsg);
				}
				return;
			}

			// h5 或者 wap
			if (wayCode === "WX_H5" || wayCode === "ALI_WAP") {
				this.payText = "请复制链接到手机端打开";
			} else {
				// 跳转到PC网站
				if (apiRes.payDataType === "payurl") {
					window.open(apiRes.payData);
				}
			}

			// 如果上面未关闭条码框，则代表进入webScoket，那么先在此处关闭条码框
			this.$emit("open");
			// 监听响应结果
			this.payOrderWebSocket = new ReconnectingWebSocket(getPayOrderWebSocketUrl(this.apiRes.payOrderId));
			this.payOrderWebSocket.onopen = () => {};
			this.payOrderWebSocket.onmessage = msgObject => {
				const resMsgObject = JSON.parse(msgObject.data);
				if (resMsgObject.state === "2") {
					this.handleClose();
					this.$message.success("支付成功， 2s后自动关闭...");

				} else {
					this.handleClose();
					this.$message.error("支付失败：" + apiRes.errCode + " ，" + apiRes.errMsg);
				}
			};
		},
		handleClose(e) {
			if (this.payOrderWebSocket) {
				this.payOrderWebSocket.close();
			}
			this.visible = false;
			this.$parent.close();
		},
	},
};
</script>
<style lang="less" scoped>
.mti-pay-code-describe {
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 30px;
		height: 25px;
	}
}
</style>
