/**
 * 支付接口定义清单
 */
export const PAY_INTERFACE_CODE= [
  {
    value: "alipay",
    name: "支付宝官方",
  },
  {
    value: "wxpay",
    name: "微信支付官方"
  },
  {
    value: "quickpass",
    name: "云闪付官方"
  },
  {
    value: "paypal",
    name: "PayPal支付"
  }
]

/**
 * 支付方式
 * @type {{UP_JSAPI: string, UP_APP: string, ALI_BAR: string, UP_PC: string, ALI_JSAPI: string, ALI_PC: string, WX_LITE: string, PAYPAL_PC: string, WX_BAR: string, UP_BAR: string, QUICK_PASS_JSAPI: string, UP_B2B: string, AUTO_QR: string, ALI_WAP: string, WX_JSAPI: string, ALI_APP: string, UP_QR: string, WX_H5: string, AUTO_BAR: string, ALI_LITE: string, QUICK_PASS_BAR: string, WX_NATIVE: string, UP_WAP: string, ALI_QR: string}}
 */
export const PAY_WAY_CODE= {
  // 收银台支付（通过二维码跳转到收银台完成支付）， 已集成获取用户ID的实现
  AUTO_QR: "AUTO_QR",
  // 条码聚合支付（自动分类条码类型）
  AUTO_BAR: "AUTO_BAR",
  // 支付宝条码支付
  ALI_BAR: "ALI_BAR",
  // 支付宝服务窗支付
  ALI_JSAPI: "ALI_JSAPI",
  // 支付宝小程序支付
  ALI_LITE: "ALI_LITE",
  // 支付宝 app支付
  ALI_APP: "ALI_APP",
  // 支付宝pc网站支付
  ALI_PC: "ALI_PC",
  // 支付宝手机网站wap支付
  ALI_WAP: "ALI_WAP",
  // 支付宝 二维码付款
  ALI_QR: "ALI_QR",
  // 云闪付条码支付
  QUICK_PASS_BAR: "QUICKPASS_BAR",
  // 云闪付服务窗支付
  QUICK_PASS_JSAPI: "QUICKPASS_JSAPI",
  // 微信jsapi支付
  WX_JSAPI: "WX_JSAPI",
  // 微信小程序支付
  WX_LITE: "WX_LITE",
  // 微信条码支付
  WX_BAR: "WX_BAR",
  // 微信H5支付
  WX_H5: "WX_H5",
  // 微信扫码支付
  WX_NATIVE: "WX_NATIVE",
  // 银联App支付
  UP_APP: "UP_APP",
  // 银联手机网站支付
  UP_WAP: "UP_WAP",
  // 银联二维码(主扫)
  UP_QR: "UP_QR",
  // 银联二维码(被扫)
  UP_BAR: "UP_BAR",
  // 银联企业网银支付
  UP_B2B: "UP_B2B",
  // 银联网关支付
  UP_PC: "UP_PC",
  // 银联JS支付
  UP_JSAPI: "UP_JSAPI",
  // PayPal 支付
  PAYPAL_PC: "PAYPAL_PC"
}

/**
 * 支付数据包类型
 */
export const PAY_DATA_TYPE= {
  // 跳转链接的方式  redirectUrl
  PAY_URL: "payurl",
  // 表单提交
  FORM: "form",
  // 微信app参数
  WX_APP: "wxapp",
  // 支付宝app参数
  ALI_APP: "aliapp",
  // 云闪付app参数
  QUICK_PASS: "quickPassApp",
  // 二维码URL
  CODE_URL: "codeUrl",
  // 二维码图片显示URL
  CODE_IMG_URL: "codeImgUrl",
  // 无参数
  NONE: "none"

}
