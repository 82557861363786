<template>
	<div class="pay-modal" v-show="open">
		<div class="modal-box animated fadeInDownBig">
			<div class="top-box">
				<div class="close-icon" @click="close"></div>
				<div class="mti-payTitle">
					订单支付
				</div>
				<!-- <div class="pay-container">
          <div
            class="pay-box"
            :class="payType == 1 ? 'pay-select' : ''"
            @click="handleChangePay(1)"
          >
            移动支付
          </div>
          <div
            class="pay-box"
            :class="payType == 2 ? 'pay-select' : ''"
            @click="handleChangePay(2)"
          >
            对公支付
          </div>
        </div> -->
			</div>
			<div class="mti-content">
				<div class="mti-orderingInformation">
					<h4>订单信息</h4>
					<el-divider class="mti-divider"></el-divider>
					<el-row v-if="!orderData.reportPay && !orderData.isPayVip">
						<el-col :span="16">
							<div class="grid-content bg-purple mti-information">
								<p>购买地区：{{ orderData.info ? orderData.info : "-" }}</p>
								<p>选择时间：{{ orderData.date ? orderData.date : "-" }}</p>
								<p>购买条数：{{ orderData.total ? orderData.total : "-" }}</p>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content bg-purple-light amount">
								<span>应付金额：</span>
								<span class="amountNumber">¥{{ orderData.price }}</span>
							</div>
						</el-col>
					</el-row>
					<el-row v-if="orderData.reportPay">
						<el-col :span="16">
							<div class="grid-content bg-purple mti-information">
								<p>服务内容：{{ orderData.info ? orderData.info : "-" }}</p>
								<div class="grid-content bg-purple-light amount">
									<span>支付金额：</span>
									<span class="amountNumber">¥{{ orderData.price }}</span>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row v-if="orderData.isPayVip">
						<el-col :span="16">
							<div class="grid-content bg-purple mti-information">
								<p>订单编号：{{ orderData.orderId ? orderData.orderId : "-" }}</p>
								<p>服务名称：{{ orderData.memberName ? orderData.memberName : "-" }}</p>
								<p>开通时长：{{ orderData.openTime ? orderData.openTime : "-" }}</p>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content bg-purple-light amount">
								<span>应付金额：</span>
								<span class="amountNumber">¥{{ orderData.price }}</span>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="mti-orderingInformation">
					<div class="pay-type">
						<h4>请选择支付方式：</h4>
						<div class="pay-container">
							<!-- <div class="pay-box" :class="payType == 1 ? 'pay-select' : ''" @click="handleChangePay(1)">
								移动支付
							</div> -->
							<div class="mti-pay-code-describe">
								<div @click="zhifubaoClick()" class="payTypeBox"
									:class="selectedType == 'zhifubao' ? 'selected' : ''">
									<img class="payIcon" src="../../assets/img/pay/ali_app.svg" alt="" />
									<!-- 支付宝图标 -->
									<span>支付宝扫码支付</span>
								</div>
								<div @click="weixinClick()" class="payTypeBox"
									:class="selectedType == 'weixin' ? 'selected' : ''">
									<img class="payIcon" src="../../assets/img/pay/wx_app.svg" alt="" />
									<!-- 微信图标 -->
									<span>微信扫码支付</span>
								</div>
							</div>
							<!-- <div class="pay-box" :class="payType == 2 ? 'pay-select' : ''" @click="handleChangePay(2)">
								对公支付
							</div> -->
						</div>
					</div>
					<div class="payCode">
						<div class="bottom-box">
							<div v-if="showFlag" style="width: 100%; height: 100%; margin-top: 100px">
								<empty name="暂无数据"></empty>
							</div>
						</div>
						<div class="bottom-box" v-if="!showFlag">
							<!-- <div class="pay-info">
								<div class="content-box">
									<div class="box-title">服务内容</div>
									<div class="box-content text-ellipsis" :title="detailData.reportTitle">
										{{ detailData.reportTitle }}
									</div>
								</div>
								<div class="content-box" style="margin-top: 4px">
									<div class="box-title">支付金额</div>
									<div class="pay-price">
										<span>￥</span>
										<span class="price" v-show="!isRead">{{ type == 1 ? detailData.readPrice : detailData.downloadPrice }}</span>
										<span class="price" v-show="isRead">{{ detailData.downloadPrice - detailData.readPrice }}</span>
									</div>
								</div>
							</div> -->
							<div class="pay-type">
								<div class="pay-mobile" v-show="payType == 1">
									<PayQRCode ref="payQRCode" :apiRow="apiRow" v-show="paymentWay != ''"></PayQRCode>
									<div class="pay-time" v-show="paymentWay != ''">
										<i class="el-icon-time"></i>
										剩
										<span class="time">{{ getTimeStr() }}</span>
										自动关闭
									</div>
									<!-- <div class="pay-mobile-type">
										<div>支付方式</div>
										<div class="type-wechat" :class="mobilePayType == 1 ? 'mobile-select' : ''" @click="handleChangeMobilePay(1)">
											<div class="type-icon"></div>
											<span>微信支付</span>
											<div class="type-select" v-show="mobilePayType == 1"></div>
										</div>
										<div class="type-alipay" :class="mobilePayType == 2 ? 'mobile-select' : ''" @click="handleChangeMobilePay(2)">
											<div class="type-icon"></div>
											<span>支付宝支付</span>
											<div class="type-select" v-show="mobilePayType == 2"></div>
										</div>
									</div> -->
									<!-- <div class="agree-box">
										<el-checkbox v-model="agreeChecked"></el-checkbox>
										<span class="content">我已阅读并同意</span>
										<span class="agree" @click="openAgree">《微信/支付宝支付须知》</span>
									</div>
									<div class="pay-msg" v-show="agreeChecked">
										<span>扫码购买</span>
										<span>打开微信/支付宝扫描二维码支付</span>
									</div>
									<div class="code-img" v-show="agreeChecked">
										<img @dragstart.prevent src="../../assets/img/code-one.png" @click="payOrder" alt="" />
										<PayQRCode ref="payQRCode" :apiRow="apiRow"></PayQRCode>
										<div class="code-mark" v-show="false">
											<span class="iconfont icon-jiazai_shuaxin"></span>
											<span>刷新二维码</span>
										</div>
									</div> -->
								</div>
								<div class="pay-contrary" v-show="payType == 2">
									<div class="msg-box" v-for="(item, i) in contraryData" :key="i">
										<div class="msg-name">{{ item.name }}</div>
										<div class="msg-item">{{ item.conunt }}</div>
									</div>
									<div class="msg-box">
										<div class="msg-name">上传支付回执</div>
										<el-upload action="*" :http-request="uploadReceipt" :on-remove="handleRemove"
											:before-upload="beforeAvatarUpload" accept="image/*" :file-list="fileList">
											<div class="upload-butoon" v-show="fileList.length == 0">
												<span class="iconfont icon-shangchuanwenjian"></span>
												<span>点击上传</span>
											</div>
										</el-upload>
									</div>
									<div class="agree-box">
										<el-checkbox v-model="contraryChecked"></el-checkbox>
										<span class="content">我已阅读并同意</span>
										<span class="agree" @click="openAgree">《对公支付须知》</span>
									</div>
									<el-button type="primary" class="publicSure" @click="submitConfirm">确定</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="bottom-box">
					<div v-if="showFlag" style="width: 100%; height: 100%; margin-top: 100px">
						<empty name="暂无数据"></empty>
					</div>
				</div>
				<div class="bottom-box" v-if="!showFlag">
					<div class="pay-info">
						<div class="content-box">
							<div class="box-title">服务内容</div>
							<div class="box-content text-ellipsis" :title="detailData.reportTitle">
								{{ detailData.reportTitle }}
							</div>
						</div>
						<div class="content-box" style="margin-top: 4px">
							<div class="box-title">支付金额</div>
							<div class="pay-price">
								<span>￥</span>
								<span class="price" v-show="!isRead">{{ type == 1 ? detailData.readPrice : detailData.downloadPrice }}</span>
								<span class="price" v-show="isRead">{{ detailData.downloadPrice - detailData.readPrice }}</span>
							</div>
						</div>
					</div>
					<div class="pay-type">
						<div class="pay-mobile" v-show="payType == 1">
							<div class="pay-mobile-type">
								<div>支付方式</div>
								<div class="type-wechat" :class="mobilePayType == 1 ? 'mobile-select' : ''" @click="handleChangeMobilePay(1)">
									<div class="type-icon"></div>
									<span>微信支付</span>
									<div class="type-select" v-show="mobilePayType == 1"></div>
								</div>
								<div class="type-alipay" :class="mobilePayType == 2 ? 'mobile-select' : ''" @click="handleChangeMobilePay(2)">
									<div class="type-icon"></div>
									<span>支付宝支付</span>
									<div class="type-select" v-show="mobilePayType == 2"></div>
								</div>
							</div>
							<div class="agree-box">
								<el-checkbox v-model="agreeChecked"></el-checkbox>
								<span class="content">我已阅读并同意</span>
								<span class="agree" @click="openAgree">《{{ mobilePayType == 1 ? "微信" : "支付宝" }}支付须知》</span>
							</div>
							<div class="pay-msg" v-show="agreeChecked">
								<span>扫码购买</span>
								<span>打开微信/支付宝扫描二维码支付</span>
							</div>
							<div class="code-img" v-show="agreeChecked">
								<img @dragstart.prevent src="../../assets/img/code-one.png" alt="" />
								<div class="code-mark" v-show="false">
									<span class="iconfont icon-jiazai_shuaxin"></span>
									<span>刷新二维码</span>
								</div>
							</div>
						</div>
						<div class="pay-contrary" v-show="payType == 2">
							<div class="msg-box" v-for="(item, i) in contraryData" :key="i">
								<div class="msg-name">{{ item.name }}</div>
								<div class="msg-item">{{ item.conunt }}</div>
							</div>
							<div class="msg-box">
								<div class="msg-name">上传支付回执</div>
								<el-upload action="*" :http-request="uploadReceipt" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" accept="image/*" :file-list="fileList">
									<div class="upload-butoon" v-show="fileList.length == 0">
										<span class="iconfont icon-shangchuanwenjian"></span>
										<span>点击上传</span>
									</div>
								</el-upload>
							</div>
							<div class="agree-box">
								<el-checkbox v-model="contraryChecked"></el-checkbox>
								<span class="content">我已阅读并同意</span>
								<span class="agree" @click="openAgree">《对公支付须知》</span>
							</div>
							<el-button type="primary" @click="submitConfirm">确定</el-button>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<agreement-modal ref="agreementModal" :title="mobilePayType == 1 ? '微信' : '支付宝' + '支付须知'"></agreement-modal>
		<agreement-modal ref="publicModal" :title="'对公支付须知'"></agreement-modal>
	</div>
</template>

<script>
import Empty from "@/components/Empty";
import AgreementModal from "@/components/agreementModal";
import PayQRCode from "@/components/PayQRCode";
export default {
	components: {
		Empty,
		AgreementModal,
		PayQRCode,
	},
	props: ["conPO"],
	data() {
		return {
			open: false,
			type: null, // 1 （阅读支付）2 （下载支付）
			payType: 1, // 1 （移动支付）2 （对公支付）
			mobilePayType: 1, // 1 （微信支付）2 （支付宝支付）
			agreeChecked: false, // 移动支付须知
			contraryChecked: false, // 对公支付须知
			detailData: {},
			showFlag: false,
			contraryData: [
				{
					name: "企业名称",
					conunt: "-",
				},
				{
					name: "银行名称",
					conunt: "-",
				},
				{
					name: "收款银行账号",
					conunt: "-",
				},
				{
					name: "银行网点名称",
					conunt: "-",
				},
				{
					name: "专属客服电话",
					conunt: "-",
				},
			],
			fileList: [], // 上传的回执
			paymentReceipt: "", // 回执id
			isRead: false, // 是否购买了阅读
			orderData: {},
			apiRow: null,
			timer: null,
			time: 600, //距离结束时间的秒数
			paymentWay: 'AUTO_QR',
			selectedType: 'zhifubao'
		};
	},
	watch: {
		// 监听弹窗控制滚动条显示与隐藏
		// open(newVal) {
		//   if (newVal) {
		//     window.document.body.style.overflowY = "hidden";
		//   } else {
		//     window.document.body.style.overflowY = "scroll";
		//   }
		// },
	},
	mounted() {
		// this.payOrder()
	},
	beforeDestroy() {
		clearTimeout(this.timer);
	},
	methods: {
		zhifubaoClick() {
			this.selectedType = "zhifubao"
			this.paymentWay = "AUTO_QR"
			this.payOrder()
		},
		weixinClick() {
			this.selectedType = "weixin"
			this.paymentWay = "WX_NATIVE"
			this.payOrder()
		},
		// 支付
		payOrder() {
			// this.$refs.payQRCode.showModal()
			const query = 0;
			const params = { orderId: this.orderData.orderId,orderSource:630201 };
			if (this.orderData.reportPay && !this.orderData.isCustomOrder) {
				let func = func =this.$api.research.reportPay({ ...params, paymentWay: this.paymentWay, paymentReceipt: "1", payDataType: "CODE_IMG_URL" })
				if (this.orderData.type == '行业动态') {
					func =this.$api.industry.industryDynamicPay({ ...params, paymentWay: this.paymentWay, paymentReceipt: "1", payDataType: "CODE_IMG_URL" })
				}
				func.then(res => {
						console.log("支付回调", res);
						console.log("this.orderData", this.orderData);
						// this.apiRow = res.data;
						this.$refs.payQRCode.apiRes = res.data;
						this.startTime();
						this.$refs.payQRCode.callWebSocket();
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else if (this.orderData.reportPay && this.orderData.isCustomOrder) {
				this.$api.consult
					.customizedPay({ ...params, paymentWay: this.paymentWay, paymentReceipt: "1", payDataType: "CODE_IMG_URL" })
					.then(res => {
						console.log("咨询/定制订单支付回调", res);
						// this.apiRow = res.data;
						this.$refs.payQRCode.apiRes = res.data;
						this.startTime();
						this.$refs.payQRCode.callWebSocket();
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else if (this.orderData.isPayVip) {
				console.log("this.orderData.isPayVip", this.orderData.isPayVip, params);
				this.$api.personalCenter
					.payMemberVip({ ...params, paymentWay: this.paymentWay, paymentReceipt: "1", payDataType: "CODE_IMG_URL" })
					.then(res => {
						console.log("会员支付回调", res);
						// this.apiRow = res.data;
						this.$refs.payQRCode.apiRes = res.data;
						this.startTime();
						this.$refs.payQRCode.callWebSocket();
						this.open = true;
					})
					.catch(msg => {
						console.log("会员支付回调111", msg);

						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				console.log(6566);

				this.$api.successful
					.payOrder(query, { ...params, paymentWay: this.paymentWay, paymentReceipt: "1", payDataType: "CODE_IMG_URL" })
					.then(res => {
						console.log("中标统计支付回调", res);
						this.$refs.payQRCode.apiRes = res.data;
						// this.$refs.payQRCode.showModal();
						this.startTime();
						this.$refs.payQRCode.callWebSocket();
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		startTime() {
			// 开启定时器 
			if (this.time > 0) {
				this.time--;
				this.getTimeStr();
				if (this.timer) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(() => {
					this.startTime()
				}, 1000);
			} else {
				clearTimeout(this.timer);
				// 超时了怎么办
				this.$message({
					message: "支付超时，请重新发起支付",
					type: "warning",
				});
				this.open = false;
			}
		},
		// 获取订单信息
		getOrderData() {
			console.log("orderData", this.orderData);
		},
		// 初始化数据
		init(type, data, read) {
			// type == 1 （阅读支付）type == 2 （下载支付）
			this.type = type;
			this.detailData = data;
			if (read) {
				// 已购买阅读
				this.isRead = true;
			}
			// 获取财务对公账户详细信息
			// this.$api.personalCenter
			//   .payReceiptInfo()
			//   .then(res => {
			//     if (res.data) {
			//       let data = res.data;
			//       this.contraryData[0].conunt = data.corpname;
			//       this.contraryData[1].conunt = data.bankName;
			//       this.contraryData[2].conunt = data.bankAccount;
			//       this.contraryData[3].conunt = data.backAddress;
			//       this.contraryData[4].conunt = data.customerServiceTelephone;
			//     } else {
			//       this.contraryData = [];
			//       this.showFlag = true;
			//     }
			//   })
			//   .catch(msg => {
			//     if (msg?.msg) {
			//   this.$message.error(msg?.msg);
			// }
			//   });
		},
		// 关闭弹窗
		close() {
			clearTimeout(this.timer);
			this.open = false;
			this.fileList = [];
			this.payType = 1;
			this.mobilePayType = 1;
			this.agreeChecked = false;
			this.contraryChecked = false;
		},
		// 点击移动支付或对公支付
		handleChangePay(type) {
			this.payType = type;
			if (type == 2) {
				this.$api.personalCenter
					.payReceiptInfo()
					.then(res => {
						if (res.data) {
							let data = res.data;
							this.contraryData[0].conunt = data.corpname;
							this.contraryData[1].conunt = data.bankName;
							this.contraryData[2].conunt = data.bankAccount;
							this.contraryData[3].conunt = data.backAddress;
							this.contraryData[4].conunt = data.customerServiceTelephone;
							this.showFlag = false;
						} else {
							this.contraryData = [];
							this.showFlag = true;
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				console.log(333);
				this.payOrder();
				this.showFlag = false;
			}
		},
		// 点击微信支付或支付宝支付
		handleChangeMobilePay(type) {
			this.mobilePayType = type;
		},
		// 上传前图片判断
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 10;
			if (!isLt2M) {
				this.$message.error("上传图片大小不能超过 10MB!");
			}
			return isLt2M;
		},
		// 上传图片
		uploadReceipt(param) {
			let file = param.file;
			let form = new FormData();
			form.append("file", file);
			const loading = this.$loading({
				lock: true,
				text: "上传中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.personalCenter
				.upload(form)
				.then(res => {
					this.$message.success("上传成功");
					file.filePath = res.data.fileName;
					file.url = res.data.url;
					this.fileList.push(file);
					this.paymentReceipt = res.data.fileId;
				})
				.catch(err => {
					this.$message.error(err?.msg);
				})
				.finally(() => {
					loading.close();
				});
		},
		// 移除上传的回执
		handleRemove(file, fileList) {
			this.fileList = fileList;
		},
		// 对公支付确认
		submitConfirm() {
			let { type, detailData, paymentReceipt, payType, mobilePayType } = this;
			if (this.fileList.length == 0) {
				this.$message.error("请上传支付回执");
				return;
			}
			if (!this.contraryChecked) {
				this.$message.error("请勾选支付须知");
				return;
			}
			let readOrDownLoad = "";
			if (type == 1) {
				readOrDownLoad = "60491001";
			} else if (type == 2) {
				readOrDownLoad = "60491002";
			}
			let paymentWay = "";
			if (payType == 1) {
				if (mobilePayType == 1) {
					paymentWay = "60491001";
				} else {
					paymentWay = "60491002";
				}
			} else {
				paymentWay = "60591003";
			}
			let params = {
				paymentReceipt,
				paymentWay,
				readOrDownLoad,
				reportId: detailData.reportId,
				terminal: "60441001",
			};
			this.$api.research
				.payReport(params)
				.then(() => {
					this.$message.success("提交成功，等待管理员审核");
					this.close();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},

		// 点击协议
		openAgree() {
			if (this.payType == "move") {
				this.$refs.agreementModal.visible = true;
				// this.$refs.agreementModal.init();
			} else {
				this.$refs.publicModal.visible = true;
			}
		},
		// 把时间转化为时分秒
		getTimeStr() {
			let letime = this.time;
			let time = {};
			let second = "00";
			if (parseInt(letime) > 60) {
				let min = parseInt(letime / 60);
				second = parseInt(letime) % 60;
				time = {
					min: min,
					second: second,
				};
				if (min > 60) {
					min = parseInt(letime / 60) % 60;
					let hour = parseInt(parseInt(letime / 60) / 60);
					// 直接返回字符串格式：
					// time = hour + "时" + min + "分" + second + "秒";
					// 返回对象格式：
					time = {
						hour: hour,
						min: min,
						second: second,
					};
					if (hour >= 24) {
						hour = parseInt(parseInt(letime / 60) / 60) % 24;
						let day = parseInt(parseInt(parseInt(letime / 60) / 60) / 24);
						// 直接返回字符串格式：
						// time = day + "天" + hour + "时" + min + "分" + second + "秒";
						// 返回对象格式：
						time = {
							day: day,
							hour: hour,
							min: min,
							second: second,
						};
					}
				}
			} else {
				second = parseInt(letime) % 60;
				time = {
					second: second,
				};
			}
			// 判断是否小于10，小于10就补零
			if (time.day < 10) {
				time.day = "0" + time.day;
			} else if (time.day == undefined) {
				time.day = "00";
			}
			if (time.hour < 10) {
				time.hour = "0" + time.hour;
			} else if (time.hour == undefined) {
				time.hour = "00";
			}
			if (time.min < 10) {
				time.min = "0" + time.min;
			} else if (time.min == undefined) {
				time.min = "00";
			}
			if (time.second < 10) {
				time.second = "0" + time.second;
			}
			// 输出结果：
			return time.day !== "00" ? time.day + "天" + time.hour + "时" + time.min + "分" + time.second + "秒" : time.day === "00" && time.hour !== "00" ? time.hour + "时" + time.min + "分" + time.second + "秒" : time.min + "分" + time.second + "秒";
		},
	},
};
</script>

<style lang="less" scoped>@import "./index.less";</style>
